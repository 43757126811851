import { dateToString } from "@app/core/global";

export class ExportAccountTransactionsRequest {
  launch: string | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;

  get parameters(): string {
    let params = ``;
    if (this.launch) params += `launch=${this.launch}&`;
    if (this.startDate) params += `startDate=${dateToString(this.startDate)}&`;
    if (this.endDate) params += `endDate=${dateToString(this.endDate)}`;
    return params;
  }
}
