import { AccountAddressModel } from './account-address.model';
import { AccountContactModel } from './account-contact.model';
import { AccountPhoneModel } from './account-phone.model';

export class AccountModel {
  id: string = '';
  productId: string | undefined;
  packageId: string | undefined;
  referralId: string | undefined;
  personId: string = '';
  branchId: string = '';
  branch: string = '';
  merchantId: string = '';
  merchant: string = '';
  operationId: string = '';
  operation: string = '';
  partnerId: string | null | undefined;
  establishmentId: string | null | undefined;
  type: string = '';
  number: string = '';
  canNegativeBalance: boolean = false;
  status: string = '';
  locked: boolean = false;
  acceptedTerm: boolean = false;

  personType: string = '';
  taxId: string = '';
  name: string = '';
  birth: Date | undefined;
  mother: string | undefined;
  father: string | undefined;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  tags: Array<string> | null | undefined;
  message: string | null | undefined;

  phones: Array<AccountPhoneModel> = [];
  contacts: Array<AccountContactModel> = [];
  addresses: Array<AccountAddressModel> = [];
}
