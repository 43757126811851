import { AccountAddressModel } from './account-address.model';
import { AccountContactModel } from './account-contact.model';
import { AccountPhoneModel } from './account-phone.model';

export class AccountAnswerableModel {
  authorize: boolean | undefined = false;
  isPartner: boolean | undefined = false;
  
  accountId: string = '';
  personId: string = '';
  userName: string = '00000000';
  name: string = '';
  taxId: string = '';
  birth: Date | undefined;
  mother: string = '';
  nationality: string | undefined | null = null;
  gender: string = '';
  pep: boolean = false;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  
  phones: AccountPhoneModel[] = new Array();
  contacts: AccountContactModel[] = new Array();
  addresses: AccountAddressModel[] = new Array();
}

export class AccountAnswerableSummaryModel {
  accountId: string = '';
  personId: string = '';
  userName: string = '00000000';
  name: string = '';
  taxId: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
}


