import { AccountAnswerableModel } from './account-answerable.model';
import { AccountModel } from './account.model';

export class AccountJuridicalModel extends AccountModel {

  tradeName: string = '';
  taxRegime: string = '';
  legalNature: string = '';
  registration: string = '';
  //answerble: AccountAnswerableModel = new AccountAnswerableModel();
}
